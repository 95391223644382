<script setup lang="ts"></script>

<template>
  <div
    class="group cursor-pointer rounded-card p-[1.25rem] text-white"
    style="
      border-radius: 0.625rem;
      background: linear-gradient(90deg, #25c9c9 2.86%, #d47dff 95.71%);
    "
    @click="
      navigateTo('https://forms.gle/PsYPo4Jqgxkwm9Z8A', {
        open: {
          target: '_blank'
        },
        external: true
      })
    "
  >
    <div class="border-b-[1px] pb-[1.25rem] font-bold">🎉 Got an interview?</div>
    <div class="flex flex-row justify-between py-[0.62rem] text-t6 text-white">
      <a
        href="https://forms.gle/PsYPo4Jqgxkwm9Z8A"
        target="_blank"
        class="font-medium group-hover:underline"
        @click.stop
        >Tell us about it and get $20 cash voucher!</a
      >
      <div class="w-[1.125rem]">
        <IconsFigmaArrowForwardOutlined />
      </div>
    </div>
  </div>
</template>
